import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  FormHelperText,
  TextField,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';

const token = JSON.parse(localStorage.getItem('user'));

CategoryTreePotEditForm.propTypes = {
  categoryTreePOtId: propTypes.array,
  categoryTreePot: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
};

export default function CategoryTreePotEditForm(props) {
  const { categoryTreePot, categoryTreePotId, closeModal, reloadData, cateChild, itemParent, parentId } = props;
  // const validation = Yup.object().shape({
  //   name: Yup.string()
  //     .required('Vui lòng nhập đầy đủ thông tin trường')
  //     .min(2, 'Vui lòng nhập tối thiểu 2 ký tự')
  //     .max(200, 'Vui lòng nhập ít hơn 200 ký tự'),
  //   order: Yup.number().required('Vui lòng nhập đầy đủ thông tin trường').min(0, 'Giá trị tối thiểu là 0'),
  // });
  const formik = useFormik({
    initialValues: categoryTreePot ?? {
      image: '',
      content: '',
      description: '',
      parentId: null,
      isActivated: true,
    },
    // validationSchema: validation,
    onSubmit: (values, formikHelpers) => {
      (categoryTreePot != null
        ? axios.put(`${process.env.REACT_APP_SERVICE_BASE_URL}/tree-pot-category/${categoryTreePot._id}`, values, {
            headers: {
              token: token.token,
            },
          })
        : axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}/tree-pot-category`, values, {
            headers: {
              token: token.token,
            },
          })
      )
        .then(() => {
          closeModal();
          reloadData();
        })
        .catch(error => {
          formikHelpers.setErrors({ error: error.response.data.message });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const uploadImage = event => {
    const f = event.target.files[0];
    const fData = new FormData();
    fData.append('file', f, f.name);
    axios
      .post(`${process.env.REACT_APP_SERVICE_BASE_URL}/upload`, fData, {
        headers: {
          token: token.token,
        },
      })
      .then(res => {
        setFieldValue('image', res.data.data.fullUrl);
      })
      .catch(error => {
        props.showSnack('error', 'Lỗi khi tải ảnh lên!');
      });
  };

  const { errors, values, touched, setErrors, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  // console.log(values._id);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Box style={{ marginBottom: '20px' }}>
            <input style={{ marginBottom: '10px' }} onChange={uploadImage} type="file" />
            <img src={values.image} alt="" />
          </Box>
          <TextField
            fullWidth
            required
            type="text"
            label="Tên"
            {...getFieldProps('content')}
            error={Boolean(touched.content && errors.content)}
          />
          {touched.content && errors.content ? <FormHelperText error>{errors.content}</FormHelperText> : null}
          <TextField
            fullWidth
            type="text"
            label="Mô tả"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
          {itemParent
            ? ''
            : cateChild && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Danh mục cha</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.parentId === null ? values.parentId = parentId : values.parentId}
                    label="Danh mục cha"
                    onChange={(e, v) => {
                      setFieldValue('parentId', e.target.value);
                    }}
                    error={Boolean(touched.parentId && errors.parentId)}
                  >
                    {categoryTreePotId?.map((v, i) =>
                      v.parentId === null ? (
                        <MenuItem key={v._id} value={v._id}>
                          {v.content}
                        </MenuItem>
                      ) : null,
                    )}
                  </Select>
                  {touched.parentId && errors.parentId ? (
                    <FormHelperText error>{errors.parentId}</FormHelperText>
                  ) : null}
                </FormControl>
              )}
          {touched.order && errors.order ? <FormHelperText error>{errors.order}</FormHelperText> : null}
          <FormControlLabel
            control={<Checkbox {...getFieldProps('isActivated')} checked={values.isActivated} name="isActivated" />}
            label="Hiện danh mục này"
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
