import { Routes, Route, Navigate } from 'react-router-dom';
// layouts
import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import DashboardLayout from './layouts/dashboard';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import NotFound from './pages/Page404';
import Product from './pages/Products';
import Category from './pages/Category';
import CategoryTreePot from './pages/CategoryTreePot';
import Order from './pages/Order';
import Blog from './pages/Blog';
import Package from './pages/Package';
import Transact from './pages/Transact';
import Banner from './pages/BannerConfig';
import TreePot from './pages/TreePot';
import TreePotNew from './pages/TreePotNew';
import Book from './pages/Books';

// ----------------------------------------------------------------------

function getToken() {
  const tokenString = localStorage.getItem('user');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

function Router() {
  const token = getToken();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackContent, setSnackContent] = useState('');
  const [snackType, setSnackType] = useState('info');

  const showSnack = (variant, message) => {
    console.log(variant, message);
    setSnackType(variant);
    setSnackContent(message);
    setIsSnackBarOpen(true);
  };

  if (!token) {
    return <Login />;
  }

  const decode = JSON.parse(atob(token.split('.')[1]));
  if (decode.exp * 1000 < new Date().getTime()) {
    console.log('Time Expired');
    return <Login />;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={3000}
        onClose={() => setIsSnackBarOpen(false)}
      >
        <Alert severity={snackType} sx={{ width: '100%' }}>
          {snackContent}
        </Alert>
      </Snackbar>
      <Routes>
        <Route index element={<Navigate to="/dashboard/app" replace />} />
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route path="app" element={<DashboardApp />} />
          <Route path="banner" element={<Banner />} />
          <Route path="tree-pot" element={<TreePot />} />
          <Route path="tree-potNew" element={<TreePotNew />} />
          <Route path="user" element={<User />} />
          <Route path="products" element={<Product showSnack={showSnack} />} />
          <Route path="category" element={<Category />} />
          <Route path="categoryTreePot" element={<CategoryTreePot />} />
          <Route path="order" element={<Order />} />
          <Route path="blog" element={<Blog />} />
          <Route path="package" element={<Package />} />
          <Route path="books" element={<Book />} />
          <Route path="transact" element={<Transact />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default Router;
