// material
import { Box, Grid, Container, Typography, Card } from '@mui/material';
import axios from 'axios';
import { useState, useEffect } from 'react';
// components
import GaugeChart from 'react-gauge-chart';
import Page from '../components/Page';
import {
  TotalUsers,
  ActiveUsers,
  BooksMonthly,
  BooksPaper,
  Ebooks,
  RevenueMonthly,
  RevenueCOD,
  RevenueOnl,
  OrderMonthly,
  OrderDeliveri,
  OrderReturn,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------
const token = JSON.parse(localStorage.getItem('user'));

const apiURL = `${process.env.REACT_APP_API_HOST}/admin/statistic`;
const apisystemURL = `${process.env.REACT_APP_API_HOST}/admin/statistic/system`;
export default function DashboardApp() {
  const [userActive, setuserActive] = useState([]);
  const [totalBooks, settotalBooks] = useState([]);
  const [totalRevenue, settotalRevenue] = useState([]);
  const [totalOrder, settotalOrder] = useState([]);
  const [systemInfo, setSystemInfo] = useState({ cpu: 0, mem: 0 });

  useEffect(() => {
    axios
      .get(`${apiURL}`, {
        headers: {
          token: token.token,
        },
      })
      .then(response => {
        setuserActive(response.data.data.users);
        settotalBooks(response.data.data.books.monthly);
        settotalRevenue(response.data.data.revenue.monthly);
        settotalOrder(response.data.data.orders.monthly);
      });
    loadSystemData();
  }, []);

  const loadSystemData = () => {
    axios
      .get(`${apisystemURL}`, {
        headers: {
          token: token.token,
        },
      })
      .then(response => {
        setSystemInfo(response.data.data.data);
        console.log(typeof (response.data.data.data.cpu / 100));
      })
      .catch(err => console.log(err));
    setTimeout(() => loadSystemData(), 15000);
  };

  return (
    <Page title="Dashboard | Admin">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Xin chào, Chào mừng bạn trở lại</Typography>
        </Box>
      </Container>
    </Page>
  );
}
