import { Form, FormikProvider, useFormik } from 'formik';
import { CKEditor } from 'ckeditor4-react';
import axios from 'axios';
import {
  Alert,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';
import { useState } from 'react';
import $ from 'jquery';
import ImageUpload from '../../../components/ImageUpload';

const token = JSON.parse(localStorage.getItem('user'));

BlogEditForm.propTypes = {
  blog: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
};

export default function BlogEditForm(props) {
  const { blog, closeModal, reloadData, handleSnackCreateSuccess } = props;
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const formik = useFormik({
    initialValues: blog ?? {
      title: '',
      content: '',
      images: [],
      mainImage: '',
    },
    onSubmit: (values, formikHelpers) => {
      (blog != null
        ? axios.put(`${process.env.REACT_APP_SERVICE_BASE_URL}/blog/${blog._id}`, values, {
            headers: {
              token: token.token,
            },
          })
        : axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}/blog`, values, {
            headers: {
              token: token.token,
            },
          })
      )
        .then(() => {
          closeModal();
          reloadData();
          handleSnackCreateSuccess();
        })
        .catch(error => {
          formikHelpers.setErrors({ error: error.response.data.message });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const { errors, values, touched, setErrors, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const removeImage = i => {
    const newImages = values.images.filter((img, ind) => {
      console.log('test', ind, i);
      return ind !== i;
    });
    setFieldValue('images', newImages);
  };

  const handleUploadImage = event => {
    setIsUploadingImage(true);
    const f = event.target.files[0];
    const fData = new FormData();
    fData.append('file', f, f.name);
    axios
      .post(`${process.env.REACT_APP_SERVICE_BASE_URL}/upload`, fData, {
        headers: {
          token: token.token,
        },
      })
      .then(res => {
        const { fileId, fullUrl } = res.data.data;
        setFieldValue('mainImage', fullUrl);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setIsUploadingImage(false));
  };

  const handleChangeContent = content => {
    console.log(content.editor.getData());
    setFieldValue('content', content.editor.getData());
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {values.images.map((v, i) => (
            <Grid key={i.toString() + new Date().toString()} item md={4}>
              <ImageUpload
                preImage={v}
                onUploadComplete={url => {
                  removeImage(i);
                }}
              />
            </Grid>
          ))}
          <Grid item md={4}>
            <ImageUpload
              showSnack={props.showSnack}
              token={token.token}
              onUploadComplete={url => setFieldValue('images', [...values.images, url])}
              preImage="ignore"
              visible={values.images.length < 4}
            />
            {errors.images ? <FormHelperText error>{errors.images}</FormHelperText> : null}
          </Grid>
          <Grid>
            <Grid item md={12}>
              <label htmlFor="upload-img-btn">
                <input
                  onChange={handleUploadImage}
                  accept="image/*"
                  style={{ display: 'none' }}
                  type="file"
                  id="upload-img-btn"
                />
                <Button variant="outlined" component="span">
                  Thêm ảnh minh họa
                </Button>
              </label>
              {isUploadingImage ? <CircularProgress /> : null}
            </Grid>
            <Grid item md={12}>
              <img src={values.mainImage} alt={values.mainImage} style={{ maxWidth: '150px', marginTop: '10px' }} />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            type="text"
            label="Tiêu đề"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <CKEditor
            config={{
              toolbar: [
                {
                  name: 'document',
                  items: ['Print'],
                },
                {
                  name: 'clipboard',
                  items: ['Undo', 'Redo'],
                },
                {
                  name: 'styles',
                  items: ['Format', 'Font', 'FontSize'],
                },
                {
                  name: 'colors',
                  items: ['TextColor', 'BGColor'],
                },
                {
                  name: 'align',
                  items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                },
                '/',
                {
                  name: 'basicstyles',
                  items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'],
                },
                {
                  name: 'links',
                  items: ['Link', 'Unlink'],
                },
                {
                  name: 'paragraph',
                  items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
                },
                {
                  name: 'insert',
                  items: ['Image', 'Table'],
                },
                {
                  name: 'tools',
                  items: ['Maximize'],
                },
                {
                  name: 'editing',
                  items: ['Scayt'],
                },
              ],

              extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

              // Adding drag and drop image upload.
              extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
              uploadUrl:
                '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

              // Configure your file manager integration. This example uses CKFinder 3 for PHP.
              filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
              filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
              filebrowserUploadUrl:
                '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
              filebrowserImageUploadUrl:
                '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',

              height: 560,

              removeDialogTabs: 'image:advanced;link:advanced',
              removeButtons: 'PasteFromWord',
            }}
            data={values.content}
            initData={values.content}
            onBlur={handleChangeContent}
            onChange={handleChangeContent}
            onSelectionChange={handleChangeContent}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
