import { Form, FormikProvider, useFormik } from 'formik';
import { CKEditor } from 'ckeditor4-react';
import axios from 'axios';
import * as Yup from 'yup';
import { Alert, Box, Button, Grid, TextField, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';
import { useState, useEffect } from 'react';
import ImageUpload from '../../../components/ImageUpload';
import Iconify from '../../../components/Iconify';
import Loading from '../../../assets/icons/loading_icon.gif';

const token = JSON.parse(localStorage.getItem('user'));

BooksEditForm.propTypes = {
  categories: propTypes.array,
  books: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
  reloadCategories: propTypes.func,
  showSnack: propTypes.func,
};

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

export default function BooksEditForm(props) {
  const { books, closeModal, reloadData } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [bookId, setBookId] = useState('');
  const [chapters, setChapters] = useState([]);

  const formik = useFormik({
    initialValues: books
      ? { ...books }
      : {
          name: '',
          coverImg: '',
          chapters: [],
          price: 0,
          description: '',
          author: '',
        },
    onSubmit: (values, formikHelpers) => {
      // console.log(values)
      (books != null
        ? axios.put(
            `${process.env.REACT_APP_SERVICE_BASE_URL}/books/${books._id}`,
            {
              name: values.name,
              coverImg: values.coverImg,
              chapters: values.chapters,
              price: values.price,
              description: values.description,
              author: values.author,
            },
            {
              headers: {
                token: token.token,
              },
            },
          )
        : axios.post(
            `${process.env.REACT_APP_SERVICE_BASE_URL}/books`,
            {
              name: values.name,
              coverImg: values.coverImg,
              chapters: values.chapters,
              price: values.price,
              description: values.description,
              author: values.author,
            },
            {
              headers: {
                token: token.token,
              },
            },
          )
      )
        .then(() => {
          // console.log(values);
          closeModal();
          reloadData();
          props.showSnack('success', 'Thành công!');
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const { errors, values, touched, isSubmitting, handleSubmit, validateForm, getFieldProps, setFieldValue } = formik;

  const handleCount = () => {
    const newChapter = { name: '', content: '', file: '' };
    setChapters([...chapters, newChapter]);
    setFieldValue('chapters', [...values.chapters, newChapter]);
  };

  const handleDeleteChapter = chapterIndex => {
    const updatedChapters = [...values.chapters];
    updatedChapters.splice(chapterIndex, 1);
    axios.put(
      `${process.env.REACT_APP_SERVICE_BASE_URL}/books/${books._id}`,
      {
        chapters: updatedChapters,
      },
      {
        headers: {
          token: token.token,
        },
      },
    );
    setFieldValue('chapters', updatedChapters);
  };

  const handleChangeNameChapter = async (e, i) => {
    const newChapters = [...values.chapters];
    newChapters[i].name = e.target.value;
    setFieldValue('chapters', newChapters);
  };

  const handleChangeContent = (content, i) => {
    const newChapters = [...values.chapters];
    newChapters[i].content = content.editor.getData();
    setFieldValue('chapters', [...values.chapters[i], newChapters]);
  };

  // console.log(values);

  const uploadPdf = (event, index) => {
    setIsUploading(true);
    const f = event.target.files[0];
    const fData = new FormData();
    fData.append('file', f, f.name);
    axios
      .post(`${process.env.REACT_APP_SERVICE_BASE_URL}/upload/pdf`, fData, {
        headers: {
          token: token.token,
        },
      })
      .then(res => {
        setIsUploading(true);
        const newFile = res.data.data.path;
        const newChapters = [...values.chapters];
        newChapters[index].file = newFile;
        setFieldValue('chapters', [...values.chapters[index], newChapters]);
        setIsUploading(false);
      })
      .catch(error => {
        console.log(error);
        props.showSnack('error', 'Lỗi khi tải file pdf lên!');
      })
      .finally(() => setIsUploading(false));
  };

  useEffect(() => {
    if (books) {
      reloadDataId();
    }
  }, []);

  const reloadDataId = kw => {
    axios
      .get(`${process.env.REACT_APP_SERVICE_BASE_URL}/books/${books._id}`, {
        headers: {
          token: token.token,
        },
      })
      .then(response => {
        setBookId(response.data.data);
      });
  };

  // console.log(bookId.book?.chapters[0].name);

  // const renderChapers = () => {
  //   const options = [];
  //   if (bookId !== '') {
  //     // for (let i = 0; i < chapters.length; i += 1)
  //     for (let i = 0; i < bookId.book.chapters?.length; i += 1)
  //       options.push(
  //         <div key={i}>
  //           <TextField
  //             sx={{ margin: '20px 0px' }}
  //             required
  //             fullWidth
  //             type="text"
  //             label="Tên chương"
  //             value={item.name}
  //             onChange={e => handleChangeNameChapter(e, i)}
  //           />
  //           <CKEditor
  //             py="10px"
  //             config={{
  //               toolbar: [
  //                 {
  //                   name: 'document',
  //                   items: ['Print'],
  //                 },
  //                 {
  //                   name: 'clipboard',
  //                   items: ['Undo', 'Redo'],
  //                 },
  //                 {
  //                   name: 'styles',
  //                   items: ['Format', 'Font', 'FontSize'],
  //                 },
  //                 {
  //                   name: 'colors',
  //                   items: ['TextColor', 'BGColor'],
  //                 },
  //                 {
  //                   name: 'align',
  //                   items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
  //                 },
  //                 '/',
  //                 {
  //                   name: 'basicstyles',
  //                   items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'],
  //                 },
  //                 {
  //                   name: 'links',
  //                   items: ['Link', 'Unlink'],
  //                 },
  //                 {
  //                   name: 'paragraph',
  //                   items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
  //                 },
  //                 {
  //                   name: 'insert',
  //                   items: ['Image', 'Table'],
  //                 },
  //                 {
  //                   name: 'tools',
  //                   items: ['Maximize'],
  //                 },
  //                 {
  //                   name: 'editing',
  //                   items: ['Scayt'],
  //                 },
  //               ],

  //               extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

  //               // Adding drag and drop image upload.
  //               extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
  //               uploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

  //               // Configure your file manager integration. This example uses CKFinder 3 for PHP.
  //               filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
  //               filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
  //               filebrowserUploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
  //               filebrowserImageUploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',

  //               height: 200,

  //               removeDialogTabs: 'image:advanced;link:advanced',
  //               removeButtons: 'PasteFromWord',
  //             }}
  //             data={values.content}
  //             initData={bookId.book?.chapters[i].content}
  //             onBlur={handleChangeContent}
  //             onChange={content => handleChangeContent(content, i)}
  //             onSelectionChange={handleChangeContent}
  //           />
  //           <Typography>Chọn file pdf</Typography>
  //           <input style={{ marginTop: '10px' }} onChange={e => uploadPdf(e, i)} type="file" />
  //           <Typography>{bookId.book.chapters[i].file}</Typography>
  //         </div>,
  //       );
  //   } else {
  //     for (let i = 0; i < chapters.length; i += 1)
  //       options.push(
  //         <div key={i}>
  //           <TextField
  //             sx={{ margin: '20px 0px' }}
  //             required
  //             fullWidth
  //             type="text"
  //             label="Tên chương"
  //             onChange={e => handleChangeNameChapter(e, i)}
  //           />
  //           <CKEditor
  //             py="10px"
  //             config={{
  //               toolbar: [
  //                 {
  //                   name: 'document',
  //                   items: ['Print'],
  //                 },
  //                 {
  //                   name: 'clipboard',
  //                   items: ['Undo', 'Redo'],
  //                 },
  //                 {
  //                   name: 'styles',
  //                   items: ['Format', 'Font', 'FontSize'],
  //                 },
  //                 {
  //                   name: 'colors',
  //                   items: ['TextColor', 'BGColor'],
  //                 },
  //                 {
  //                   name: 'align',
  //                   items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
  //                 },
  //                 '/',
  //                 {
  //                   name: 'basicstyles',
  //                   items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'],
  //                 },
  //                 {
  //                   name: 'links',
  //                   items: ['Link', 'Unlink'],
  //                 },
  //                 {
  //                   name: 'paragraph',
  //                   items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
  //                 },
  //                 {
  //                   name: 'insert',
  //                   items: ['Image', 'Table'],
  //                 },
  //                 {
  //                   name: 'tools',
  //                   items: ['Maximize'],
  //                 },
  //                 {
  //                   name: 'editing',
  //                   items: ['Scayt'],
  //                 },
  //               ],

  //               extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

  //               // Adding drag and drop image upload.
  //               extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
  //               uploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

  //               // Configure your file manager integration. This example uses CKFinder 3 for PHP.
  //               filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
  //               filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
  //               filebrowserUploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
  //               filebrowserImageUploadUrl:
  //                 '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',

  //               height: 200,

  //               removeDialogTabs: 'image:advanced;link:advanced',
  //               removeButtons: 'PasteFromWord',
  //             }}
  //             data={values.content}
  //             initData={values.content}
  //             onBlur={handleChangeContent}
  //             onChange={content => handleChangeContent(content, i)}
  //             onSelectionChange={handleChangeContent}
  //           />
  //           <Typography>Chọn file pdf</Typography>
  //           <input style={{ marginTop: '10px' }} onChange={e => uploadPdf(e, i)} type="file" />
  //           {isUploading && (
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <img style={{ width: '60px' }} src={Loading} alt="" /> Đang tải file...
  //             </Box>
  //           )}
  //         </div>,
  //       );
  //   }
  //   return <div>{options}</div>;
  // };

  const uploadImage = event => {
    const f = event.target.files[0];
    const fData = new FormData();
    fData.append('file', f, f.name);
    axios
      .post(`${process.env.REACT_APP_SERVICE_BASE_URL}/upload`, fData, {
        headers: {
          token: token.token,
        },
      })
      .then(res => {
        setFieldValue('coverImg', res.data.data.fullUrl);
      })
      .catch(error => {
        props.showSnack('error', 'Lỗi khi tải ảnh lên!');
      });
  };

  // console.log(values);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <Box style={{ marginBottom: '20px' }}>
                <input style={{ marginBottom: '10px' }} onChange={uploadImage} type="file" />
                <img src={values.coverImg} alt="" />
              </Box>
              <Stack spacing={3}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Tên sách"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            type="number"
            label="Giá"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
            onKeyDown={e => {
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
          />
          <TextField
            required
            fullWidth
            type="text"
            label="Mô tả"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
          <TextField
            required
            fullWidth
            type="text"
            label="Tác giả"
            {...getFieldProps('author')}
            error={Boolean(touched.author && errors.author)}
            helperText={touched.author && errors.author}
          />
          <Box sx={{ display: 'flex' }} alignItems="center" justifyContent="space-between">
            <Typography>Các chương</Typography>
            <Button variant="contained" onClick={handleCount} startIcon={<Iconify icon="eva:plus-fill" />}>
              Thêm chương
            </Button>
          </Box>
          {values.chapters?.map((item, i) => (
            <div key={i}>
              <TextField
                sx={{ margin: '20px 0px' }}
                required
                fullWidth
                type="text"
                label="Tên chương"
                value={values.chapters[i]?.name}
                onChange={e => handleChangeNameChapter(e, i)}
              />
              <CKEditor
                py="10px"
                config={{
                  toolbar: [
                    {
                      name: 'document',
                      items: ['Print'],
                    },
                    {
                      name: 'clipboard',
                      items: ['Undo', 'Redo'],
                    },
                    {
                      name: 'styles',
                      items: ['Format', 'Font', 'FontSize'],
                    },
                    {
                      name: 'colors',
                      items: ['TextColor', 'BGColor'],
                    },
                    {
                      name: 'align',
                      items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                    },
                    '/',
                    {
                      name: 'basicstyles',
                      items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting'],
                    },
                    {
                      name: 'links',
                      items: ['Link', 'Unlink'],
                    },
                    {
                      name: 'paragraph',
                      items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
                    },
                    {
                      name: 'insert',
                      items: ['Image', 'Table'],
                    },
                    {
                      name: 'tools',
                      items: ['Maximize'],
                    },
                    {
                      name: 'editing',
                      items: ['Scayt'],
                    },
                  ],

                  extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

                  // Adding drag and drop image upload.
                  extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
                  uploadUrl:
                    '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

                  // Configure your file manager integration. This example uses CKFinder 3 for PHP.
                  filebrowserBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html',
                  filebrowserImageBrowseUrl: '/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
                  filebrowserUploadUrl:
                    '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
                  filebrowserImageUploadUrl:
                    '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',

                  height: 200,

                  removeDialogTabs: 'image:advanced;link:advanced',
                  removeButtons: 'PasteFromWord',
                }}
                data={values.chapters[i]?.content}
                initData={values.chapters[i]?.content}
                onBlur={handleChangeContent}
                onChange={content => handleChangeContent(content, i)}
                onSelectionChange={handleChangeContent}
              />
              <Typography>Chọn file pdf</Typography>
              <input style={{ marginTop: '10px' }} onChange={e => uploadPdf(e, i)} type="file" />
              {isUploading && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ width: '60px' }} src={Loading} alt="" /> Đang tải file...
                </Box>
              )}
              <Typography>{values.chapters[i]?.file}</Typography>
              <Box sx={{ display: 'flex', marginTop: '10px' }} alignItems="center" justifyContent="space-between">
                <Button
                  variant="contained"
                  onClick={() => handleDeleteChapter(i)}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Xóa chương
                </Button>
              </Box>
            </div>
          ))}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
