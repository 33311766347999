import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { Alert, Button, FormControl, FormHelperText, Grid, Stack, TextField, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';
import { useState } from 'react';
import ImageUpload from '../../../components/ImageUpload';
import Iconify from '../../../components/Iconify';
import CategoryAddForm from './CategoryAddForm';

const token = JSON.parse(localStorage.getItem('user'));

ProductEditForm.propTypes = {
  categories: propTypes.array,
  product: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
  reloadCategories: propTypes.func,
  showSnack: propTypes.func,
};

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

export default function ProductEditForm(props) {
  const { categories, product, closeModal, reloadData } = props;
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState('');
  const ProductFormValidation = Yup.object().shape({
    name: Yup.string()
      .required('Vui lòng nhập đầy đủ thông tin trường')
      .min(2, 'Vui lòng nhập tối thiểu 2 ký tự')
      .max(200, 'Vui lòng nhập ít hơn 200 ký tự'),
    category: Yup.string().required('Vui lòng chọn danh mục'),
    price: Yup.number().required('Vui lòng nhập đầy đủ thông tin trường').min(0, 'Giá trị tối thiểu là 0'),
    prePrice: Yup.number().min(0, 'Vui lòng nhập giá trị lớn hơn 0'),
    numberOfPage: Yup.number().required('Vui lòng nhập đầy đủ thông tin trường').min(0, 'Giá trị tối thiểu là 0'),
    description: Yup.string().required('Vui lòng nhập đầy đủ thông tin trường'),
    images: Yup.array().min(1, 'Vui lòng tải lên tối thiểu 1 ảnh minh họa'),
  });
  const formik = useFormik({
    initialValues: product
      ? { ...product }
      : {
          name: '',
          category: '',
          mainImage: '',
          price: 0,
          prePrice: 0,
          images: [],
          weight: '',
          isBuyNow: true,
          description: '',
        },
    onSubmit: (values, formikHelpers) => {
      (product != null
        ? axios.put(
            `${process.env.REACT_APP_SERVICE_BASE_URL}/product/${product._id}`,
            {
              name: values.name,
              category: values.category,
              mainImage: values.mainImage,
              price: values.price / 1000,
              prePrice: values.prePrice / 1000,
              images: values.images,
              weight: values.weight,
              isBuyNow: true,
              description: values.description,
            },
            {
              headers: {
                token: token.token,
              },
            },
          )
        : axios.post(
            `${process.env.REACT_APP_SERVICE_BASE_URL}/product`,
            {
              name: values.name,
              category: values.category,
              mainImage: values.mainImage,
              price: values.price / 1000,
              prePrice: values.prePrice / 1000,
              images: values.images,
              weight: values.weight,
              isBuyNow: true,
              description: values.description,
            },
            {
              headers: {
                token: token.token,
              },
            },
          )
      )
        .then(() => {
          console.log(values);
          closeModal();
          reloadData();
          props.showSnack('success', 'Thành công!');
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const { errors, values, touched, isSubmitting, handleSubmit, validateForm, getFieldProps, setFieldValue } = formik;
  console.log(values);
  const removeImage = i => {
    const newImages = values.images.filter((img, ind) => {
      console.log('test', ind, i);
      return ind !== i;
    });
    setFieldValue('images', newImages);
  };

  const getOpObj = option => {
    if (!option._id) option = categories.find(op => op._id === option);
    return option;
  };
  const handleOpenDialog = f => {
    setForm(f);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={5}>
            {values.images.map((v, i) => (
              <Grid key={i.toString() + new Date().toString()} item md={4}>
                <ImageUpload
                  preImage={v}
                  onUploadComplete={url => {
                    removeImage(i);
                  }}
                />
              </Grid>
            ))}
            <Grid item md={4}>
              <ImageUpload
                showSnack={props.showSnack}
                token={token.token}
                onUploadComplete={url => setFieldValue('images', [...values.images, url])}
                preImage="ignore"
                visible={values.images.length < 4}
              />
              {errors.images ? <FormHelperText error>{errors.images}</FormHelperText> : null}
            </Grid>

            <Grid item md={12}>
              <Stack spacing={3}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label="Tên sản phẩm"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      required
                      id="combo-box-category"
                      options={categories}
                      getOptionLabel={option => (getOpObj(option) ? getOpObj(option).name : '')}
                      onChange={(e, v) => {
                        setFieldValue('category', getOpObj(v)._id);
                      }}
                      value={values.category}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label="Danh mục"
                          error={Boolean(touched.category && errors.category)}
                        />
                      )}
                    />
                    {touched.category && errors.category ? (
                      <FormHelperText error>{errors.category}</FormHelperText>
                    ) : null}
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenDialog('category')}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Thêm
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <TextField
            fullWidth
            type="number"
            label="Giá Xu"
            {...getFieldProps('prePrice')}
            error={Boolean(touched.prePrice && errors.prePrice)}
            helperText={touched.prePrice && errors.prePrice}
            InputProps={{
              endAdornment: 'xu',
            }}
            onKeyDown={e => {
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
          />
          <TextField
            required
            fullWidth
            type="number"
            label="Giá Xu mới"
            {...getFieldProps('price')}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price}
            InputProps={{
              endAdornment: 'xu',
            }}
            onKeyDown={e => {
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
          />
          <TextField
            required
            fullWidth
            type="text"
            label="Mô tả"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
