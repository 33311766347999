import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { Alert, Checkbox, FormControlLabel, FormHelperText, Stack, TextField, Grid, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';

const token = JSON.parse(localStorage.getItem('user'));

PackageEditForm.propTypes = {
  packages: propTypes.object,
  status: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
};

export default function PackageEditForm(props) {
  const { packages, type, closeModal, reloadData } = props;
  // const validation = Yup.object().shape({
  //   status: Yup.string().required('Vui lòng nhập đầy đủ thông tin trường'),
  // });
  const formik = useFormik({
    initialValues: packages ?? {
      title: '',
      type: '',
      cost: '',
    },
    // validationSchema: validation,
    onSubmit: (values, formikHelpers) => {
      (packages != null
        ? axios.put(`${process.env.REACT_APP_SERVICE_BASE_URL}/package/${packages._id}`, values, {
            headers: {
              token: token.token,
            },
          })
        : axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}/package`, values, {
            headers: {
              token: token.token,
            },
          })
      )
        .then(() => {
          closeModal();
          reloadData();
        })
        .catch(error => {
          formikHelpers.setErrors({ error: error.response.data.message });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const { errors, values, touched, setErrors, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  const getOpObj = option => {
    if (!option._id) option = type.find(op => op._id === option);
    return option;
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Tiêu đề"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <Grid item md={12}>
                <Stack spacing={3}>
                  <Autocomplete
                    required
                    id="combo-box-type"
                    options={type}
                    getOptionLabel={option => (getOpObj(option) ? getOpObj(option).name : '')}
                    onChange={(e, v) => {
                      setFieldValue('type', getOpObj(v)._id);
                    }}
                    value={values.type}
                    renderInput={params => (
                      <TextField {...params} required label="Loại" error={Boolean(touched.type && errors.type)} />
                    )}
                  />
                </Stack>
              </Grid>
              <TextField
                fullWidth
                type="text"
                label="Giá"
                {...getFieldProps('cost')}
                error={Boolean(touched.cost && errors.cost)}
                helperText={touched.cost && errors.cost}
              />
            </Stack>
          </Grid>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
