import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import {
  Alert,
  Button,
  InputLabel,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  Stack,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const token = JSON.parse(localStorage.getItem('user'));

TreePotNewEditForm.propTypes = {
  treepotNew: propTypes.object,
  categories: propTypes.array,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
};

export default function TreePotNewEditForm(props) {
  const { treepotNew, closeModal, reloadData, handleSnackCreateSuccess, categories } = props;
  console.log(treepotNew);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const formik = useFormik({
    initialValues: treepotNew
      ? { ...treepotNew }
      : {
          url: '',
          cost: 0,
          type: 1,
          categoryId: '',
        },
    onSubmit: (values, formikHelpers) => {
      (treepotNew != null
        ? axios.put(`${process.env.REACT_APP_SERVICE_BASE_URL}/tree-pot-new/${treepotNew._id}`, {
          url: values.url,
          cost: values.cost,
          type: 1,
          categoryId: typeof values.categoryId._id === "string" ? values.categoryId._id : values.categoryId,
        }, {
            headers: {
              token: token.token,
            },
          })
        : axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}/tree-pot-new`, values, {
            headers: {
              token: token.token,
            },
          })
      )
        .then(() => {
          closeModal();
          reloadData();
          handleSnackCreateSuccess();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  const { errors, values, touched, setErrors, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    reloadData();
  }, []);

  const getOpObj = option => {
    if (!option._id) option = categories.find(op => op._id === option);
    return option;
  };

  const handleUploadImage = event => {
    setIsUploadingImage(true);
    const f = event.target.files[0];
    const fData = new FormData();
    fData.append('file', f, f.name);
    axios
      .post(`${process.env.REACT_APP_SERVICE_BASE_URL}/upload`, fData, {
        headers: {
          token: token.token,
        },
      })
      .then(res => {
        const { fileId, fullUrl } = res.data.data;
        setFieldValue('url', fullUrl);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setIsUploadingImage(false));
  };

  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedSubCategory, setSelectedSubCategory] = useState(values.categoryId);

  // console.log(selectedSubCategory);

  const handleCategoryChange = event => {
    // setSelectedCategory(event.target.value)
    setFieldValue('categoryId', event.target.value);
  };

  // const handleSubCategoryChange = (event, value) => {
  //   setSelectedSubCategory(value);
  //   setFieldValue('categoryChild', getOpObj(value)._id);
  // };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <img src={values.url} alt={values.url} style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item md={8}>
              <label htmlFor="upload-img-btn">
                <input
                  onChange={handleUploadImage}
                  accept="image/*"
                  style={{ display: 'none' }}
                  type="file"
                  id="upload-img-btn"
                />
                <Button variant="outlined" component="span">
                  Thêm ảnh minh họa
                </Button>
              </label>
              {isUploadingImage ? <CircularProgress /> : null}
            </Grid>
          </Grid>
          <TextField
            required
            fullWidth
            type="number"
            label="Giá"
            {...getFieldProps('cost')}
            error={Boolean(touched.cost && errors.cost)}
            helperText={touched.cost && errors.cost}
          />
          <Grid item md={12}>
            <Stack spacing={3}>
              {/* <Autocomplete
                required
                id="combo-box-type"
                options={categories}
                getOptionLabel={option => (getOpObj(option) ? getOpObj(option).content : '')}
                onChange={handleCategoryChange}
                value={values.categoryParent}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Danh mục cha"
                    error={Boolean(touched.categoryId && errors.categoryId)}
                  />
                )}
              />
              {selectedCategory && (
                <Autocomplete
                  id="combo-box-subcategory"
                  options={selectedCategory.subCategories}
                  getOptionLabel={option => option.content}
                  onChange={(e, v) => handleSubCategoryChange(e, v)}
                  value={values.categoryId}
                  renderInput={params => <TextField {...params} label="Danh mục con" />}
                />
              )} */}
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                >
                  {categories.map(item => (
                    // <>
                      {item.subCategories.map(v => (
                        <MenuItem value={10}>----{v.content}</MenuItem>
                      ))}
                    // </>
                  ))}
                </Select>
              </FormControl> */}
              <select
                defaultValue={values.categoryId._id}
                onChange={e => handleCategoryChange(e)}
                style={{ padding: '10px', borderRadius: '10px' }}
              >
                <option style={{ padding: '10px' }}>Chọn danh mục</option>
                {categories.map(item => (
                  <>
                    <option disabled value={item._id} style={{ padding: '10px' }}>
                      {item.content}
                    </option>
                    {item.subCategories.map(v => (
                      <option value={v._id} style={{ padding: '10px' }}>
                        ----{v.content}
                      </option>
                    ))}
                  </>
                ))}
              </select>
            </Stack>
          </Grid>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
