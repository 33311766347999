import { Form, FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { Alert, Checkbox, FormControlLabel, FormHelperText, Stack, TextField, Grid, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types';

const token = JSON.parse(localStorage.getItem('user'));

TransactEditForm.propTypes = {
  transact: propTypes.object,
  status: propTypes.object,
  closeModal: propTypes.func,
  reloadData: propTypes.func,
};

export default function TransactEditForm(props) {
  const { transact, status, closeModal, reloadData } = props;
  const validation = Yup.object().shape({
    status: Yup.string().required('Vui lòng nhập đầy đủ thông tin trường'),
  });
  const formik = useFormik({
    initialValues: transact ?? {
      status: '',
    },
    validationSchema: validation,
    onSubmit: (values, formikHelpers) => {
      axios.put(`${process.env.REACT_APP_SERVICE}/order/admin/change-status/${transact._id}`, values, {
        headers: {
          token: token.token,
        },
      })
        .then(() => {
          closeModal();
          reloadData();
        })
        .catch(error => {
          formikHelpers.setErrors({ error: error.response.data.message });
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });


  const { errors, values, touched, setErrors, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  const getOpObj = option => {
    if (!option._id) option = status.find(op => op._id === option);
    return option;
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Grid item md={12}>
                <Stack spacing={3}>
                  <Autocomplete
                    required
                    id="combo-box-type"
                    options={status}
                    getOptionLabel={option => (getOpObj(option) ? getOpObj(option).name : '')}
                    onChange={(e, v) => {
                      setFieldValue('status', getOpObj(v)._id);
                    }}
                    value={values.status}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label="Trạng thái"
                        error={Boolean(touched.status && errors.status)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Stack>
          </Grid>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />
        {errors.error != null && (
          <Alert severity="error">
            {typeof errors.error === 'string' ? (
              <p>{errors.error}</p>
            ) : (
              errors.error.map((v, i) => <p key={i.toString()}>{v}</p>)
            )}
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Lưu
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
