// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = name => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Quản lý banner',
    path: '/dashboard/banner',
    icon: getIcon('codicon:note'),
  },
  {
    title: 'Quản lý tài khoản',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Quản lý sản phẩm',
    path: '/dashboard/products',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Quản lý danh mục',
    path: '/dashboard/category',
    icon: getIcon('ic:outline-category'),
  },
  {
    title: 'Quản lý danh mục chậu',
    path: '/dashboard/categoryTreePot',
    icon: getIcon('charm:plant-pot'),
  },
  {
    title: 'Quản lý chậu và cành',
    path: '/dashboard/tree-pot',
    icon: getIcon('ic:round-rate-review'),
  },
  {
    title: 'Quản lý đơn hàng',
    path: '/dashboard/order',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Quản lý blog',
    path: '/dashboard/blog',
    icon: getIcon('dashicons:welcome-write-blog'),
  },
  {
    title: 'Quản lý gói',
    path: '/dashboard/package',
    icon: getIcon('ph:package-duotone'),
  },
  {
    title: 'Lịch sử giao dịch',
    path: '/dashboard/transact',
    icon: getIcon('ant-design:transaction-outlined'),
  },
  {
    title: 'Quản lý sách',
    path: '/dashboard/books',
    icon: getIcon('material-symbols:menu-book-rounded'),
  },
  {
    title: 'Quản lý chậu và cành(*)',
    path: '/dashboard/tree-potNew',
    icon: getIcon('charm:plant-pot'),
  },
];

export default sidebarConfig;
